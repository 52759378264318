<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Item wise report
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-form ref="frm">
                  <v-card-text class="px-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1">Item Name</label>

                        <v-autocomplete :items="item_names" item-value="item_name" item-text="item_name"
                          v-model="item_name" class="
                                                            input-style
                                                            font-size-input
                                                            text-light-input
                                                            placeholder-light
                                                            select-style
                                                            mt-5
                                                          " outlined chips multiple height="46" single-line
                          placeholder="Select Item Name">
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                              <span class="text-white text-caption ls-0">{{
                                item.item_name
                              }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ item_name.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggle">
                              <v-list-item-action>
                                <v-icon :color="
                                  item_name.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                ">
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>From (Start date)</label>

                        <v-menu ref="start_date_menu" v-model="start_date_menu" :close-on-content-click="false"
                          :return-value.sync="start_date_menu" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="editedItem.start_date" v-bind="attrs" v-on="on" hide-details="auto"
                              class="
                                                                input-style
                                                                font-size-input
                                                                text-light-input
                                                                placeholder-light
                                                                input-icon
                                                                mt-5
                                                              " dense flat filled solo height="46"
                              placeholder="Start Date" persistent-hint></v-text-field>
                          </template>
                          <v-date-picker color="green lighten-1" header-color="primary" v-model="editedItem.start_date"
                            :max="editedItem.end_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="start_date_menu = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="
                              $refs.start_date_menu.save(
                                editedItem.start_date
                              )
                            ">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                            </strong></span>To
                          (End date)</label>

                        <v-menu ref="end_date_menu" v-model="end_date_menu" :close-on-content-click="false"
                          :return-value.sync="end_date_menu" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="editedItem.end_date" v-bind="attrs" v-on="on" hide-details="auto"
                              class="
                                                                input-style
                                                                font-size-input
                                                                text-light-input
                                                                placeholder-light
                                                                input-icon
                                                                mt-5
                                                              " dense flat filled solo height="46" placeholder="To Date"
                              persistent-hint></v-text-field>
                          </template>
                          <v-date-picker color="green lighten-1" header-color="primary" v-model="editedItem.end_date"
                            :min="editedItem.start_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="end_date_menu = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="
                              $refs.end_date_menu.save(editedItem.end_date)
                            ">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions dense class="card-padding d-flex justify-end">
                    <v-btn @click="close" elevation="0" :ripple="false" height="43" class="
                                                        font-weight-bold
                                                        text-capitalize
                                                        btn-ls btn-secondary
                                                        bg-light
                                                        px-6
                                                      ">Clear</v-btn>

                    <v-btn @click="searchPurchase()" elevation="0" :ripple="false" height="43" dark class="
                                                        font-weight-bold
                                                        text-capitalize
                                                        btn-ls btn-primary
                                                        bg-success
                                                        px-6
                                                      ">Search</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card-text>
              <v-card-text class="px-0 py-0" v-if="items.length > 0">
                <v-data-table :headers="headers" :items="items" item-key="id" class="table" :footer-props="{
                  'items-per-page-options': [100, 200, 500, -1],
                }" mobile-breakpoint="0">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Item wise Report</v-toolbar-title>

                      <v-spacer></v-spacer>
                      <v-btn @click="excelExport" elevation="0" :ripple="false" height="43" dark class="
                                                          font-weight-bold
                                                          text-capitalize
                                                          btn-primary
                                                          bg-success
                                                          py-5
                                                          px-5
                                                          shadow
                                                        ">
                        <v-icon size="20" class="text-white mr-1">
                          fas fa-cloud-download-alt
                        </v-icon>
                        Download
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.sale_date`]="{ item }">
                    <span>
                      {{ formatDate(item.sale_date) }}
                    </span>
                  </template>
                  <template v-slot:[`item.sl_no`]="{ item }">
                    <span>
                      {{ findSerialNo(item) }}
                    </span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
export default {
  name: "PurchaseReport",
  data() {
    return {
      item_names: [],
      item_name: [],
      page: 1,
      pageCount: 0,
      overlay: false,
      buyingType: [
        { name: "AUCTION" },
        { name: "PRIVATE" },
        { name: "INTERNAL TRANSFER" },
      ],

      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",
      editedIndex: -1,
      start_date_menu: false,
      end_date_menu: false,
      editedItem: {
        buyingType: "",
        start_date: null,
        end_date: null,
      },
      defaultItem: {
        buyingType: "",
        start_date: null,
        end_date: null,
      },
      headers: [
        {
          text: "#",
          width: "80",
          value: "sl_no",
        },
        {
          text: "Company Warehouse",
          // width: "150",
          value: "company_warehouse",
        },
        {
          text: "Date",
          width: "150",
          value: "sale_date",
        },
        {
          text: "Week",
          width: "100",
          value: "week",
        },
        {
          text: "Mark",
          // width: "150",
          value: "mark",
        },
        {
          text: "Grade",
          width: "150",
          value: "grade",
        },
        {
          text: "Invoice",
          width: "150",
          value: "invoice",
        },
        {
          text: "Item Name",
          value: "item_name",
        },
        {
          text: "Batch No.",
          // width: "150",
          value: "batch_no",
        },
        {
          text: "Bay",
          // width: "100",
          value: "bay",
        },
        {
          text: "Bags",
          // width: "120",
          value: "bags",
          align: "right",
        },
        {
          text: "Wt",
          // width: "120",
          value: "wt",
          align: "right",
        },
        {
          text: "Nett",
          // width: "120",
          value: "nett",
          align: "right",
        },
        {
          text: "Rate",
          // width: "120",
          value: "rate",
          align: "right",
        },
        {
          text: "Amount",
          // width: "120",
          value: "amount",
          align: "right",
        },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    async setDates() {
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.editedItem.start_date = this.formatDate(d);
      this.editedItem.end_date = this.formatDate(d1);
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    validate() {
      return this.$refs.frm.validate();
    },
    findSerialNo(item) {
      return this.items.indexOf(item) + 1;
    },
    excelExport() {
      let date_time = new Date();
      date_time = `${(date_time.getMonth() + 1).toString().padStart(2, "0")}/${date_time.getDate().toString().padStart(2, "0")}/${date_time
        .getFullYear()
        .toString()}`;
      let fileName = `Itemwise Purchase-` + date_time.replaceAll(`/`, `-`);

      let data = this.items.map((item, i) => {
        return {
          "#": i + 1,
          "Company Warehouse": item.company_warehouse,
          "Date": this.formatDate(item.sale_date),
          "Week": item.week,
          "Mark": item.mark,
          "Grade": item.grade,
          "Invoice": item.invoice,
          "Item Name": item.item_name,
          "Batch No.": item.batch_no,
          "Bay": item.bay,
          "Bags": item.bags,
          "Wt": item.wt,
          "Nett": item.nett,
          "Rate": item.rate,
          "Amount": item.amount,
        };
      });

      try {
        json2excel({
          data,
          name: fileName,
          formateDate: "yyyy/mm/dd",
        });
      } catch (e) {
        console.error("Excel export error:", e);
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,

        showConfirmButton: false,
      });
    },

    async initialize() {
      try {
        this.item_names = await api.getItems();
        this.setDates();
      } catch (err) {
        this.showErrorAlert(err);
      }
    },

    async filterSeasonSale() {
      this.overlay = true;
      this.editedItem.seasonSale = 0;
      this.seasonSale = await apiMaster.getSeasonSaleBySeason(
        this.editedItem.season
      );
      this.overlay = false;
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    closeDialogue() {
      this.dialog = false;
      this.$refs.frmSave.reset();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
      });
    },
    reset() {
      this.$refs.frm.reset();
    },

    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    saveValidation() {
      return this.$refs.frmSave.validate();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.item_name = [];
        } else {
          this.item_name = this.item_names.slice();
        }
      });
    },
    async searchPurchase() {
      if (this.validate()) {
        this.items = [];
        let item_names = [];
        // Checking Item name -SELECT ALL-
        if (this.item_name.length == this.item_names.length) {
          this.item_name.forEach((el) => {
            item_names.push(el.item_name);
          });
        }
        // SELECT SOME
        if (
          this.item_name.length > 0 &&
          this.item_name.length != this.item_names.length
        ) {
          item_names = this.item_name;
        }

        this.overlay = true;
        try {
          let slug = {
            // purchase_type: this.editedItem.buyingType,
            item_name: item_names,
            fromDate: this.editedItem.start_date,
            toDate: this.editedItem.end_date,
          };

          this.items = await api.getPurchaseReport(slug);
          if (this.items.length == 0) {
            this.noDataAlert("No Purchase Data Found");
          }
        } catch (err) {
          this.showErrorAlert(err.message);
        } finally {
          this.overlay = false;
        }
      }
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Allocation" : "Edit Allocation";
    },
    selectAll() {
      return this.item_name.length === this.item_names.length;
    },
    selectSome() {
      return this.item_name.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>
