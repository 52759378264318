import axios from "axios";

export default {
  async getPurchaseReport(data) {
    return await axios
      .get(`report/purchase-report`, { params: data })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPurchaseReportMain(data) {
    return await axios
      .get(`report/purchase-report-main`, { params: data })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getGrnNo(fromDate, toDate) {
    return await axios
      .get(`dispatch/grn-no?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getVendors(types) {
    // , { params: { type: types } }
    return await axios
      .get(`vendors/vendor/by/types`, {
        params: {
          purchase_type: types,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPurchaseReportItemWise(params) {
    return await axios
      .get(`report/purchase-report`, { params: params })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getFilteredCatalogue(slug) {
    return await axios
      .get("catalogue-upload/catalogue-referece-report?" + slug)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getItems() {
    return await axios
      .get(`purchase-auction/item-name`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getReportBySales(id) {
    return await axios
      .get(`report/purchase-report-by-season-sale/` + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getAuctionCenterBySeason(id) {
    return await axios
      .get(`report/get-auctioncenter-by-season/` + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getVendorBySeason(id) {
    return await axios
      .get(`report/get-vendor-by-season/` + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getSeasonSalesByAcutionCenters(param) {
    return await axios
      .get(`report/get-sale-by-auctioncenters`, {
        params: param,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getOfferByVendor(param) {
    return await axios
      .get(`report/get-offer-by-vendors`, {
        params: param,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
